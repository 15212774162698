import API from "../../models/api-client";
import { AuthParameterInterface } from "./Auth.interface";

export const getToken = (dataObject: AuthParameterInterface) => {
  return API.post({
    url: "/login",
    payload: dataObject,
  });
};

export const refreshToken = (token: string) => {
  return API.post({
    url: "/refreshToken",
    payload: {
      token: token,
    },
  });
};

export const logoutUser = () => {
  return API.post({
    url: "/logout",
  });
};
