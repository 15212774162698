import { store } from "./../store";
import * as Sentry from "@sentry/vue";

export const isValidJWT = (jwt: string) => {
  if (jwt === undefined || jwt.split(".").length < 3) {
    return false;
  } else {
    try {
      const data = JSON.parse(atob(jwt.split(".")[1]));
      const expiryDate = new Date(data.exp * 1000);
      const currentDate = new Date();
      return currentDate < expiryDate && data.rol !== "UNASSIGNED";
    } catch {
      return false;
    }
  }
};

export const jwtUserInfo = (jwt: string | undefined) => {
  if (jwt === undefined) {
    Sentry.setUser(null);
    return {};
  }
  if (!isValidJWT(jwt)) {
    store.dispatch("logout", { reason: "tokenexpired" });
    Sentry.setUser(null);
    return {};
  }
  const user = JSON.parse(atob(jwt.split(".")[1]));
  Sentry.setUser({ username: user["sub"] });
  return user;
};
