import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { store } from "../store";
import menuRoutes from "../models/menu/menu";
import { isValidJWT, jwtUserInfo } from "../helpers/authenticationHelper";
// Declare any custom meta fields in the @/models/menu/menu.ts here
declare module "vue-router" {
  interface RouteMeta {
    roles?: string[];
  }
}

const BASE_TITLE = `XSQ.Pro - ${window.APPLICATION_DOMAIN}`;

const routes: Array<RouteRecordRaw> = menuRoutes;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(function (to, from, next) {
  // Prevent reloads/logout upon clicking the menu collapse buttons
  window.document.title = `${BASE_TITLE} - ${
    to.meta && to.meta.title ? to.meta.title : ""
  }`;
  if (to.fullPath === from.fullPath && to.name === from.name) {
    next();
    return;
  }

  if (to.meta.roles) {
    const routeRoles: string[] = to.meta.roles;

    // Dispatch fetch of new user token/userinfo to refresh token
    if (
      (store.getters.token === undefined || store.getters.token === null) &&
      to.path === "/dashboard"
    ) {
      next({ name: "Login" });
      return;
    } else if (!store.getters.token) {
      store.dispatch("settoast", {
        type: "warn",
        title: "Not logged in",
        message: "Please log in in order to access this page.",
        time: 5000,
      });
      next({ name: "Login", query: { redirect: to.fullPath } });
      return;
    }

    const jwtValid = isValidJWT(store.getters.token);

    // Check if user is logged in with a valid token
    if (!jwtValid) {
      store.dispatch("logout");
      store.dispatch("settoast", {
        type: "error",
        title: "Session invalid",
        message: "Session token was invalidated. You have been logged out",
        time: 10000,
      });
      next({ name: "Login", query: { redirect: to.fullPath } });
      return;
    }

    // Admins can do anything
    if (jwtUserInfo(store.getters.token).rol === "ADMIN") {
      if (to.query.redirect) {
        next({ path: to.query.redirect.toString() });
        return;
      }
      next();
      return;
    } else if (routeRoles.includes(jwtUserInfo(store.getters.token).rol)) {
      if (to.query.redirect) {
        next({ path: to.query.redirect.toString() });
        return;
      }
      next();
      return;
    } else if (jwtUserInfo(store.getters.token).rol === "FS") {
      // this if block is added so FS won't go to dashboard
      // a page they cannot access, without this it will be an infinite loop
      next({ name: "L2 WorkOrders" });
      return;
    } else {
      store.dispatch("settoast", {
        type: "error",
        title: "No permission",
        message:
          "You do not have permissions for the page you tried to access.",
        time: 10000,
      });
      next({ name: "MainLayout" });
      return;
    }
  } else {
    if (
      to.path === "/login" &&
      store.getters.token !== undefined &&
      store.getters.token !== null
    ) {
      next({ name: "MainLayout" });
      return;
    }
    // Check if target page is login && user is logged in..
    // if (to.query.redirect) {
    //   next({ path: to.query.redirect.toString() });
    //   return;
    // }
    next();
  }
});

export default router;
