// import store from "../../store";

const menuRoutes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "MainLayout",
    component: () => import("../../views/MainLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../../views/Dashboard.vue"),
        meta: {
          roles: ["ISP"],
          title: "Dashboard",
        },
      },
      {
        path: "/orders/allorders",
        name: "Orders",
        badge: 0,
        component: () => import("../../views/Orders/AllOrders.vue"),
        meta: {
          roles: ["ISP"],
          title: "All orders",
        },
      },
      {
        path: "/orders/neworders",
        name: "NewOrders",
        component: () => import("../../views/Orders/NewOrders.vue"),
        meta: {
          roles: ["ISP"],
          title: "New orders",
        },
      },
      {
        path: "/orders/workorders",
        name: "L2 WorkOrders",
        component: () => import("../../views/Orders/WorkOrders.vue"),
        meta: {
          roles: ["FS"],
          title: "L2 Work orders",
        },
      },
      {
        path: "/orders/map",
        name: "Map",
        component: () => import("../../views/Orders/OrdersMap.vue"),
        meta: {
          roles: ["ISP"],
          title: "Orders Map",
        },
      },
      {
        path: "/orders/popmap",
        name: "PopMap",
        component: () => import("../../views/Orders/PopsMap.vue"),
        meta: {
          roles: ["ISP"],
          title: "Pops Map",
        },
      },
      {
        path: "/admin/provisioning",
        name: "ProvisioningStatus",
        component: () =>
          import("../../views/Administration/ProvisioningLog.vue"),
        meta: {
          roles: [],
          title: "Provisioning status",
        },
      },
      {
        path: "/admin/maincidents",
        name: "Maincident",
        component: () => import("../../views/Administration/Maincident.vue"),
        meta: {
          roles: ["ISP"],
          title: "Maintenance and incidents",
        },
      },
      {
        path: "/admin/isps",
        name: "Service Providers",
        component: () => import("../../views/Administration/ISPs.vue"),
        meta: {
          roles: [],
          title: "ISP management",
        },
      },
      {
        path: "/admin/fs",
        name: "Field Service",
        component: () => import("../../views/Administration/FieldService.vue"),
        meta: {
          roles: [],
          title: "Field Service management",
        },
      },
      {
        path: "/admin/users",
        name: "Users",
        component: () => import("../../views/Administration/Users.vue"),
        meta: {
          roles: [],
          title: "User management",
        },
      },
      {
        path: "/admin/devices",
        name: "Devices",
        component: () => import("../../views/Administration/Devices.vue"),
        meta: {
          roles: ["ISP"],
          title: "Device management",
        },
      },
      {
        path: "/admin/ont",
        name: "ONTs",
        component: () => import("../../views/Administration/NTU.vue"),
        meta: {
          roles: [],
          title: "ONT management",
        },
      },
      {
        path: "/admin/services",
        name: "ServiceProfiles",
        component: () =>
          import("../../views/Administration/ServiceProfiles.vue"),
        meta: {
          roles: ["ISP"],
          title: "Service profile management",
        },
      },
      {
        path: "/admin/pop",
        name: "POPs",
        component: () => import("../../views/Administration/POP.vue"),
        meta: {
          roles: [],
          title: "POP management",
        },
      },
      {
        path: "/admin/address",
        name: "Address",
        component: () => import("../../views/Administration/Address.vue"),
        meta: {
          roles: [],
          title: "Address management",
        },
      },
      {
        path: "/admin/settings",
        name: "Settings",
        component: () => import("../../views/Administration/Settings.vue"),
        meta: {
          roles: [],
          title: "Setting management",
        },
      },
      {
        path: "/admin/status",
        name: "Status",
        component: () => import("../../views/Administration/Status.vue"),
        meta: {
          roles: [],
          title: "Status",
        },
      },
      {
        path: "/admin/actionlog",
        name: "Action Log",
        component: () => import("../../views/Administration/ActionLog.vue"),
        meta: {
          roles: [],
          title: "Action Log",
        },
      },
      {
        path: "/admin/applicationlog",
        name: "Application Log",
        component: () =>
          import("../../views/Administration/ApplicationLog.vue"),
        meta: {
          roles: [],
          title: "Application Log",
        },
      },
      {
        path: "/admin/infofetcher",
        name: "Info Fetcher",
        component: () => import("../../views/Administration/InfoFetcher.vue"),
        meta: {
          roles: [],
          title: "Info Fetcher",
        },
      },
      {
        path: "/support",
        name: "Support",
        component: () => import("../../views/Support.vue"),
        meta: {
          roles: ["ISP"],
          title: "Support tickets",
        },
      },
      {
        path: "/support/ticket/:ticketNumber",
        name: "TicketDetails",
        component: () => import("../../views/TicketDetails.vue"),
        meta: {
          roles: ["ISP"],
          title: "Ticket -",
        },
      },
      {
        path: "/support/serviceCheck/:serviceCheckID",
        name: "ServiceCheck",
        component: () => import("../../views/ServiceCheck.vue"),
        meta: {
          roles: ["ISP"],
          title: "Service Check -",
        },
      },
      {
        path: "/version",
        name: "VersionHistory",
        component: () => import("../../views/Version.vue"),
        meta: {
          title: "Version History",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../../views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

export default menuRoutes;
