import axios, { AxiosResponse, AxiosError } from "axios";
import { ApiClientParametersInterface } from "./ApiClientParameters.interface";
import { store } from "../../store";

const HTTP = axios.create({
  baseURL: "/api/" + window.APPLICATION_API_VERSION,
  headers: {
    Authorization: undefined,
  },
});

export default {
  async get<T = any>(
    parameters: ApiClientParametersInterface,
  ): Promise<AxiosResponse<T>> {
    const axiosResponse = HTTP.get(parameters.url, {
      params: parameters.payload,
      headers: { Authorization: store.getters.token },
    })
      .then((response: AxiosResponse) => {
        if (response.data.token) {
          store.dispatch("updateToken", response.data.token);
        }
        return Promise.resolve(response);
      })
      .catch((error: AxiosError) => {
        if (
          error.message.toLowerCase() == "network error" ||
          error.message.toLowerCase() == "request aborted"
        ) {
          return Promise.reject(error);
        }
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            store.dispatch("logout", {
              reason: "tokenexpired",
            });
          }
        } else {
          store.dispatch("logout", {
            reason: "tokenexpired",
          });
        }
        return Promise.reject(error);
      });

    return axiosResponse;
  },
  async post(parameters: ApiClientParametersInterface): Promise<AxiosResponse> {
    const axiosResponse = HTTP.post(parameters.url, parameters.payload, {
      headers: { Authorization: store.getters.token },
    })
      .then((response: AxiosResponse) => {
        if (response.data.token) {
          store.dispatch("updateToken", response.data.token);
        }
        return Promise.resolve(response);
      })
      .catch((error: AxiosError) => {
        if (
          error.message.toLowerCase() == "network error" ||
          error.message.toLowerCase() == "request aborted"
        ) {
          return Promise.reject(error);
        }
        if (parameters.url === "/logout") {
          return Promise.reject(error);
        }
        if (error.response !== undefined) {
          if (
            error.response.status === 401 &&
            error.response.data.message !== "Failed to authenticate"
          ) {
            store.dispatch("logout", {
              reason: "tokenexpired",
            });
          }
        }
        return Promise.reject(error);
      });
    return axiosResponse;
  },
};
